import axios from 'axios';
import router from "@/router";
// import MD5 from "./md5.js"//MD5加密js
// import { setCookie,getCookie } from './Cookie'
//引入路由
// import { Message } from "element-ui"
import Qs from 'qs'

//配置统一配置
// axios.defaults.baseURL = "http://47.109.39.141:8080/app/";
axios.defaults.baseURL = "https://pay.fkykj.cn/app/";
// axios.defaults.baseURL = "http://192.168.1.108:8001/app/";
axios.defaults.withCredentials = true;

//请求拦截器
axios.interceptors.request.use(config => {//config是一个对象
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  config.headers['content-type'] = 'application/json';
  // var cur_time=new Date().getTime();
  // var newkey = Object.keys(config.params).sort();
  // 　　//先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  // var newObj = {};//创建一个新的对象，用于存放排好序的键值对
  // for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
  //     newObj[newkey[i]] = config.params[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
  // }

  // config.headers['auth-timestamp'] = cur_time;
  // config.headers['auth-sign'] =MD5.hex_md5(MD5.hex_md5('ShnTQeoX693a5bMlkR')+MD5.hex_md5(newObj));
  
  return config
})

//响应拦截器
axios.interceptors.response.use(res => {//res是一个对象
  // console.log("响应拦截",res);
  if (res) {
    let data = res.data;
    if (data !== undefined && data.code !== undefined && data.msg !== undefined) {
      //解构code和msg
      let { code, msg } = data;

      if (code === 1) {//成功
        //弹出提示
        // Message({
        //   //this指向有问题，需要引入element-ui中message
        //   type:"success",
        //   message:msg
        // })
      } else if (code === 0) {
        // Message.error(msg);
      } else if (code == 10001) {
        router.push("/")
      }

    }

    return res;
  }

}, err => {
  console.log(err)
  // Message.error("网络请求错误")
  // router.push("/login");//这里没有vue实例  所以需要new一个实例才可以用push
})

//暴露出去
export default {
  //get请求方式
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params })
        .then(res => {
          if (res) {
            resolve(res.data);//请求成功
          }
        })
        .catch(err => {
          reject(err);//请求失败
        })
    })
  },
  getExecl(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }, { responseType: "blob" })
        .then(res => {
          if (res) {
            resolve(res.data);//请求成功
          }
        })
        .catch(err => {
          reject(err);//请求失败
        })
    })
  },
  //post请求放方式
  post(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, params)
        .then(res => {
          if (res) {
            resolve(res.data)//请求成功
          }
        })
        .catch(err => {
          reject(err)//请求失败
        })
    })
  },
  axios

}
